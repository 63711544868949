import React from 'react';
import './scrollDown.css';

const ScrollDown = () => {
    return (
        <div className="scroll-container">
            <div className="scroll"></div>
        </div>
    );
}

export default ScrollDown;
