import React, { useState } from 'react';
import NisipeanuButton from '../../components/ui/nisipeanuButton';
import SocialIcons from "../../components/ui/socialIcons";
import { motion } from "framer-motion";
import { RiMailStarLine } from "react-icons/ri";
import emailjs from 'emailjs-com';
import { ToastContainer } from "react-toastify";
import NotificationManager from '../../utils/NotificationManager';
import { useTranslation } from 'react-i18next'; // Import translation hook

const spring = {
    type: "spring",
    damping: 10,
    stiffness: 100
};

const Contact = () => {
    const { t } = useTranslation(); // Initialize translation hook
    const [email, setEmail] = useState('');
    const [subject, setSubject] = useState('');
    const [message, setMessage] = useState('');

    const isMobile = window.innerWidth <= 768;

    const sendEmail = (e: { preventDefault: () => void; }) => {
        e.preventDefault();

        const templateParams = {
            from_email: email,
            subject: subject,
            message: message,
        };

        emailjs.send('service_sg0vf9c', 'template_ylgl46f', templateParams, 'dg6qmrSiwlFPMzd39')
            .then((response) => {
                console.log('SUCCESS!', response.status, response.text);
                NotificationManager.showNotification(1, t('contact.successNotification')); // Translated success notification
            }, (error) => {
                console.log('FAILED...', error);
                NotificationManager.showNotification(2, t('contact.errorNotification')); // Translated error notification
            });

        setEmail('');
        setSubject('');
        setMessage('');
    };

    return (
        <div className="p-4 rounded-lg shadow w-full h-full flex flex-col text-white">
            {isMobile ? (
                <div className="flex flex-col items-center gap-4 h-full">
                    <motion.div
                        className="text-[60px] text-nisipeanu-blue"
                        animate={{ scale: [1, 1.2, 1] }}
                        transition={spring}
                        style={{ filter: 'drop-shadow(0 0 20px rgba(0, 122, 255, 0.5))' }}
                    >
                        <motion.div
                            animate={{
                                filter: [
                                    'drop-shadow(0 0 20px rgba(0, 122, 255, 0.5))',
                                    'drop-shadow(0 0 40px rgba(0, 122, 255, 1))',
                                    'drop-shadow(0 0 20px rgba(0, 122, 255, 0.5))'
                                ]
                            }}
                            transition={{
                                duration: 1.5,
                                repeat: Infinity,
                                repeatType: "reverse",
                            }}
                        >
                            <RiMailStarLine />
                        </motion.div>
                    </motion.div>
                    <div className="rounded-lg p-6 shadow-inner flex flex-col justify-center w-full max-w-md">
                        <section>
                            <div className="py-4 px-4 mx-auto">
                                <form onSubmit={sendEmail} className="space-y-4">
                                    <div>
                                        <label htmlFor="email"
                                               className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                                            {t('contact.yourEmail')} 
                                        </label>
                                        <input
                                            type="email"
                                            id="email"
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                            className="shadow-sm bg-nisipeanu-grey border border-nisipeanu-grey-light text-nisipeanu-white text-base rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-3"
                                            placeholder={t('contact.placeholderEmail')} 
                                            required
                                        />
                                    </div>
                                    <div>
                                        <label htmlFor="subject"
                                               className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                                            {t('contact.subject')} 
                                        </label>
                                        <input
                                            type="text"
                                            id="subject"
                                            value={subject}
                                            onChange={(e) => setSubject(e.target.value)}
                                            className="shadow-sm bg-nisipeanu-grey border border-nisipeanu-grey-light text-nisipeanu-white text-base rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-3"
                                            placeholder={t('contact.placeholderSubject')} 
                                            required
                                        />
                                    </div>
                                    <div>
                                        <label htmlFor="message"
                                               className="block mb-2 text-sm font-medium text-white dark:text-gray-400">
                                            {t('contact.yourMessage')} 
                                        </label>
                                        <textarea
                                            id="message"
                                            value={message}
                                            onChange={(e) => setMessage(e.target.value)}
                                            className="shadow-sm bg-nisipeanu-grey border border-nisipeanu-grey-light text-nisipeanu-white text-base rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-3"
                                            placeholder={t('contact.placeholderMessage')} 
                                            required
                                            rows={4}
                                        />
                                    </div>
                                    <NisipeanuButton text={t('contact.sendMessageButton')} /> {/* Translated button */}
                                </form>
                            </div>
                        </section>
                    </div>
                </div>
            ) : (
                <div className="grid grid-cols-2 gap-4 h-full">
                    <div className="grid grid-rows-[80%_20%] gap-4 h-full">
                        <div className="rounded-lg p-4 flex flex-col justify-center text-nisipeanu-white">
                            <div className="flex items-center justify-center">
                                <motion.div
                                    className="text-[400px] text-nisipeanu-blue"
                                    animate={{ scale: [1, 1.2, 1] }}
                                    transition={spring}
                                    style={{ filter: 'drop-shadow(0 0 20px rgba(0, 122, 255, 0.5))' }}
                                >
                                    <motion.div
                                        animate={{
                                            filter: [
                                                'drop-shadow(0 0 20px rgba(0, 122, 255, 0.5))',
                                                'drop-shadow(0 0 40px rgba(0, 122, 255, 1))',
                                                'drop-shadow(0 0 20px rgba(0, 122, 255, 0.5))'
                                            ]
                                        }}
                                        transition={{
                                            duration: 1.5,
                                            repeat: Infinity,
                                            repeatType: "reverse",
                                        }}
                                    >
                                        <RiMailStarLine />
                                    </motion.div>
                                </motion.div>
                            </div>
                        </div>
                        <div className="rounded-lg flex flex-col justify-center text-black pl-8 social-icons-container hidden md:block">
                            <p className="text-center text-sm text-nisipeanu-white">{t('contact.socialFollow')}</p> 
                            <SocialIcons />
                        </div>
                    </div>
                    <div className="rounded-lg p-6 shadow-inner flex flex-col justify-center">
                        <section>
                            <div className="py-8 lg:py-16 px-4 mx-auto max-w-screen-md">
                                <h2 className="mb-4 text-4xl text-center text-nisipeanu-white hidden md:block">
                                    {t('contact.title')} 
                                </h2>
                                <form onSubmit={sendEmail} className="space-y-8">
                                    <div>
                                        <label htmlFor="email"
                                               className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                                            {t('contact.yourEmail')} 
                                        </label>
                                        <input
                                            type="email"
                                            id="email"
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                            className="shadow-sm bg-nisipeanu-grey border border-nisipeanu-grey-light text-nisipeanu-white text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5"
                                            placeholder={t('contact.placeholderEmail')} 
                                            required
                                        />
                                    </div>
                                    <div>
                                        <label htmlFor="subject"
                                               className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                                            {t('contact.subject')} 
                                        </label>
                                        <input
                                            type="text"
                                            id="subject"
                                            value={subject}
                                            onChange={(e) => setSubject(e.target.value)}
                                            className="shadow-sm bg-nisipeanu-grey border border-nisipeanu-grey-light text-nisipeanu-white text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5"
                                            placeholder={t('contact.placeholderSubject')} 
                                            required
                                        />
                                    </div>
                                    <div className="sm:col-span-2">
                                        <label htmlFor="message"
                                               className="block mb-2 text-sm font-medium text-white dark:text-gray-400">
                                            {t('contact.yourMessage')} 
                                        </label>
                                        <textarea
                                            id="message"
                                            value={message}
                                            onChange={(e) => setMessage(e.target.value)}
                                            className="shadow-sm bg-nisipeanu-grey border border-nisipeanu-grey-light text-nisipeanu-white text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5"
                                            placeholder={t('contact.placeholderMessage')} 
                                            required
                                        />
                                    </div>
                                    <NisipeanuButton text={t('contact.sendMessageButton')} /> {/* Translated button */}
                                </form>
                            </div>
                        </section>
                    </div>
                </div>
            )}
            <div className="absolute top-4 right-4 shadow-nisipeanu-blue">
                <motion.div
                    className="h-8 w-8 border-8 border-nisipeanu-blue rounded-full"
                    initial={{ boxShadow: '0 0 8px rgba(0, 122, 206, 0.6)' }}
                    animate={{ boxShadow: ['0 0 8px rgba(0, 122, 206, 0.6)', '0 0 16px rgba(0, 122, 206, 0.9)'] }}
                    transition={{ duration: 1.5, repeat: Infinity, repeatType: 'reverse' }}
                />
            </div>
        </div>
    );
}

export default Contact;
