import React from 'react';
import SocialIcons from "./ui/socialIcons";

function Footer() {
    return (
        <footer className="bg-nisipeanu-black-dark text-nisipeanu-white p-4 pl-5 pr-5">
            <div className=" flex justify-between items-center lg:mr-10 lg:ml-10">
                <div className="flex-initial">
                    <p className="text-sm">
                        Nisipeanu Ionut PFA © 2024
                    </p>
                    <p className='text-sm'>contact@nisipeanu.tech</p>
                    <p className='text-sm'>+40 725 675 064</p>
                </div>
                <div className="flex-initial">
                    <SocialIcons />
                </div>
            </div>
        </footer>
    );
}

export default Footer;
