import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import MonitorPresentation from '../../components/sections/MonitorPresentation';
import AboutMe from '../AboutMe';
import CareerHighlights from '../CareerHighlights';
import SwipingZone from '../SwipingZone';
import { useScroll } from '../../utils/ScrollContext';
import { useLocation } from 'react-router-dom';

const HomePage: React.FC = () => {
    const { sectionRefs } = useScroll();
    const location = useLocation();

    useEffect(() => {
        if (location.state?.section) {
            const section = location.state.section;
            const sectionRef = sectionRefs[section];
            if (sectionRef?.current) {
                sectionRef.current.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, [location, sectionRefs]);

    return (
        <>
            {/* SEO for HomePage in Romanian for nisipeanu.tech */}
            <Helmet>
                <html lang="ro" />
                <title>Acasă - nisipeanu.tech, Soluții Complete de Dezvoltare Web</title>
                <meta
                    name="description"
                    content="nisipeanu.tech oferă soluții de dezvoltare web personalizate și scalabile, specializându-ne în tehnologii moderne precum React, Next.js și Inteligența Artificială pentru afaceri din România."
                />
                <meta
                    name="keywords"
                    content="dezvoltare web, soluții web, React, Next.js, inteligență artificială, scalabilitate, România, firme de dezvoltare web"
                />
                <meta property="og:title" content="nisipeanu.tech - Soluții Complete de Dezvoltare Web" />
                <meta
                    property="og:description"
                    content="Explorează serviciile oferite de nisipeanu.tech, firma de dezvoltare web specializată în crearea de site-uri personalizate și soluții digitale pentru afaceri moderne."
                />
                <meta property="og:url" content={window.location.href} />
                <meta property="og:type" content="website" />
                <meta property="og:image" content="https://nisipeanu.tech/images/logo.jpg" />
                <meta name="robots" content="index, follow" />
                <link rel="canonical" href={window.location.href} />
            </Helmet>

            <main className="pt-16 min-h-screen bg-nisipeanu-grey text-white flex flex-col space-y-16">
                <section className="flex-grow">
                    <MonitorPresentation />
                </section>
                <section ref={sectionRefs.aboutMe} className="flex-grow" id="about-me">
                    <AboutMe />
                </section>
                <section ref={sectionRefs.careerHighlights} className="flex-grow" id="career-highlights">
                    <CareerHighlights />
                </section>
                <section ref={sectionRefs.swipingZone} className="flex-grow" id="swiping-zone">
                    <SwipingZone />
                </section>
            </main>
        </>
    );
};

export default HomePage;
