import React, { useState, useEffect, useRef } from 'react';
import { motion, useAnimation, useInView } from 'framer-motion';
import ProjectCard from './ProjectCard';
import NisipeanuButton from '../../components/ui/nisipeanuButton';
import projects from './projects';
import { IoMdArrowDropdown, IoMdArrowDropup } from 'react-icons/io';
import { useTranslation } from 'react-i18next';

const PortfolioPage: React.FC = () => {
    const { t } = useTranslation();
    const [openCardIndex, setOpenCardIndex] = useState<number | null>(null);
    const [columns, setColumns] = useState<number>(3);
    const [selectedTechnology, setSelectedTechnology] = useState<string>('');
    const [selectedCategory, setSelectedCategory] = useState<string>('');
    const [selectedProjectType, setSelectedProjectType] = useState<string>('');
    const [showFilters, setShowFilters] = useState<boolean>(false);

    const controls = useAnimation();
    const ref = useRef<HTMLDivElement>(null);
    const isInView = useInView(ref, { once: true });

    useEffect(() => {
        if (isInView) {
            controls.start('visible');
        }
    }, [controls, isInView]);

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth <= 768) {
                setColumns(1);
            } else if (window.innerWidth <= 1024) {
                setColumns(2);
            } else {
                setColumns(3);
            }
        };

        window.addEventListener('resize', handleResize);
        handleResize();

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const lineVariants = {
        hidden: { scaleX: 0 },
        visible: { scaleX: 3, transition: { duration: 0.4 } }
    };

    const circleVariants = {
        hidden: { scale: 0 },
        visible: { scale: 1, transition: { delay: 0.4, duration: 0.2 } }
    };

    const filteredProjects = projects.filter(project => {
        return (
            (selectedTechnology === '' || project.technologies.includes(selectedTechnology)) &&
            (selectedCategory === '' || project.category.includes(selectedCategory)) &&
            (selectedProjectType === '' || project.projectType === selectedProjectType)
        );
    });

    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.5 }}
            className="flex flex-col min-h-screen pt-16 bg-nisipeanu-black-rau overflow-x-hidden"
        >
            <motion.div
                initial={{ y: -50, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ delay: 0.3 }}
                className="flex justify-center mb-4"
            >
                <div className="flex flex-col items-center">
                    <h1 className="text-4xl text-white pt-20">{t('portfolio.title')}</h1>
                    <p className="text-white pb-2 text-center max-w-2xl">
                        {t('portfolio.description')}
                    </p>
                    <div ref={ref} className="mb-4 flex flex-col items-center">
                        <div className="relative w-full flex">
                            <motion.div
                                className="h-2 w-44 left bg-nisipeanu-blue shadow-glow ri"
                                initial="hidden"
                                animate={controls}
                                variants={lineVariants}
                            />
                            <motion.div
                                className="w-8 h-8 rounded-full border-8 border-nisipeanu-blue shadow-glow left-[350px] bottom-[-10px]"
                                initial="hidden"
                                animate={controls}
                                variants={circleVariants}
                                style={{ position: 'absolute' }}
                            />
                        </div>
                    </div>
                </div>
            </motion.div>
            <motion.div
                initial={{ y: -50, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ delay: 0.6 }}
                className="flex justify-center mb-4"
            >
                <div className="bg-nisipeanu-black p-6 rounded-lg shadow-lg flex flex-col md:flex-row items-center md:items-start space-y-4 md:space-y-0 md:space-x-4 w-full max-w-5xl">
                    <div className="relative flex flex-col md:flex-row items-center md:hidden">
                        <button
                            onClick={() => setShowFilters(!showFilters)}
                            className="flex items-center text-white p-2 rounded bg-nisipeanu-black border border-gray-400"
                        >
                            {showFilters ? t('portfolio.filterDropdown.hide') : t('portfolio.filterDropdown.show')}
                            {showFilters ? <IoMdArrowDropup className="ml-2" /> : <IoMdArrowDropdown className="ml-2" />}
                        </button>
                    </div>
                    {showFilters && (
                        <div className="flex flex-col space-y-4 md:space-y-0 md:flex-row md:space-x-4 w-full md:w-auto">
                            <div className="relative flex flex-col md:flex-row items-center">
                                <label className="text-white mr-2">{t('portfolio.technology')}:</label>
                                <select
                                    value={selectedTechnology}
                                    onChange={(e) => setSelectedTechnology(e.target.value)}
                                    className="p-2 rounded bg-nisipeanu-black text-white border border-gray-400"
                                >
                                    <option value="">{t('portfolio.filters.all')}</option>
                                    {Object.entries(t('portfolio.technologies', { returnObjects: true }) as Record<string, string>).map(([key, value], index) => (
                                        <option key={index} value={key}>{value}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="relative flex flex-col md:flex-row items-center">
                                <label className="text-white mr-2">{t('portfolio.category')}:</label>
                                <select
                                    value={selectedCategory}
                                    onChange={(e) => setSelectedCategory(e.target.value)}
                                    className="p-2 rounded bg-nisipeanu-black text-white border border-gray-400"
                                >
                                    <option value="">{t('portfolio.filters.all')}</option>
                                    {Object.entries(t('portfolio.categories', { returnObjects: true }) as Record<string, string>).map(([key, value], index) => (
                                        <option key={index} value={key}>{value}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="relative flex flex-col md:flex-row items-center">
                                <label className="text-white mr-2">{t('portfolio.projectType')}:</label>
                                <select
                                    value={selectedProjectType}
                                    onChange={(e) => setSelectedProjectType(e.target.value)}
                                    className="p-2 rounded bg-nisipeanu-black text-white border border-gray-400"
                                >
                                    <option value="">{t('portfolio.filters.all')}</option>
                                    {Object.entries(t('portfolio.projectTypes', { returnObjects: true }) as Record<string, string>).map(([key, value], index) => (
                                        <option key={index} value={key}>{value}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="flex flex-col md:flex-row items-center">
                                <div className="mt-2 md:mt-0">
                                    <NisipeanuButton
                                        text={t('portfolio.filters.clear')}
                                        onClick={() => {
                                            setSelectedTechnology('');
                                            setSelectedCategory('');
                                            setSelectedProjectType('');
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    )}
                    {!showFilters && (
                        <div className="relative hidden md:flex md:flex-row md:space-x-4 w-full max-w-5xl">
                            <div className="flex items-center">
                                <label className="text-white mr-2">{t('portfolio.technology')}:</label>
                                <select
                                    value={selectedTechnology}
                                    onChange={(e) => setSelectedTechnology(e.target.value)}
                                    className="p-2 rounded bg-nisipeanu-black text-white border border-gray-400"
                                >
                                    <option value="">{t('portfolio.filters.all')}</option>
                                    {Object.entries(t('portfolio.technologies', { returnObjects: true }) as Record<string, string>).map(([key, value], index) => (
                                        <option key={index} value={key}>{value}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="flex items-center">
                                <label className="text-white mr-2">{t('portfolio.category')}:</label>
                                <select
                                    value={selectedCategory}
                                    onChange={(e) => setSelectedCategory(e.target.value)}
                                    className="p-2 rounded bg-nisipeanu-black text-white border border-gray-400"
                                >
                                    <option value="">{t('portfolio.filters.all')}</option>
                                    {Object.entries(t('portfolio.categories', { returnObjects: true }) as Record<string, string>).map(([key, value], index) => (
                                        <option key={index} value={key}>{value}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="flex items-center">
                                <label className="text-white mr-2">{t('portfolio.projectType')}:</label>
                                <select
                                    value={selectedProjectType}
                                    onChange={(e) => setSelectedProjectType(e.target.value)}
                                    className="p-2 rounded bg-nisipeanu-black text-white border border-gray-400"
                                >
                                    <option value="">{t('portfolio.filters.all')}</option>
                                    {Object.entries(t('portfolio.projectTypes', { returnObjects: true }) as Record<string, string>).map(([key, value], index) => (
                                        <option key={index} value={key}>{value}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="flex items-center">
                                <NisipeanuButton
                                    text={t('portfolio.filters.clear')}
                                    onClick={() => {
                                        setSelectedTechnology('');
                                        setSelectedCategory('');
                                        setSelectedProjectType('');
                                    }}
                                />
                            </div>
                        </div>
                    )}
                </div>
            </motion.div>
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.9 }}
                className="flex flex-1 justify-center items-center pb-20"
            >
                <div className={`w-full max-w-5xl grid gap-4 p-4`} style={{ gridTemplateColumns: `repeat(${columns}, minmax(0, 1fr))` }}>
                    {filteredProjects.map((project, index) => (
                        <ProjectCard
                            key={index}
                            title={project.title}
                            prizes={project.prizes}
                            category={Array.isArray(project.category) ? project.category : [project.category]}
                            projectType={project.projectType}
                            technologies={project.technologies}
                            backgroundImage={project.backgroundImage}
                            isOpen={openCardIndex === index}
                            onOpen={() => setOpenCardIndex(index)}
                            onClose={() => setOpenCardIndex(null)}
                            isMobile={columns === 1}
                        >
                            {project.component}
                        </ProjectCard>
                    ))}
                </div>
            </motion.div>
        </motion.div>
    );
};

export default PortfolioPage;
