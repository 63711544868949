import { isMobile, isTablet } from 'react-device-detect';

// Device types definition
export type DeviceType = 'phone' | 'tablet' | 'laptop' | 'pc';

/**
 * Utility function to detect the device type, combining media queries and react-device-detect.
 */
export const getDeviceType = (): DeviceType => {
  const width = window.innerWidth;

  // Media queries for phone and tablet
  if (width <= 640 || isMobile) {
    return 'phone';
  } else if (width > 640 && width <= 1024 || isTablet) {
    return 'tablet';
  }

  // Device detection for laptop and PC
  if (width > 1024 && width < 1440) {
    return 'laptop';
  } else if (width >= 1440) {
    return 'pc';
  }

  // Default fallback
  return 'phone';
};

/**
 * Utility to apply Tailwind classes based on the device type.
 */
export const applyResponsiveClasses = (deviceType: DeviceType, classMap: { [key in DeviceType]?: string }): string => {
  return classMap[deviceType] || '';
};
