// utils/ScrollContext.tsx
import React, { createContext, useContext, useRef, ReactNode, RefObject } from 'react';

interface ScrollContextProps {
    sectionRefs: {
        [key: string]: RefObject<HTMLDivElement>;
    };
    scrollToSection: (section: string) => void;
    scrollToTop: () => void;
}

const ScrollContext = createContext<ScrollContextProps | undefined>(undefined);

export const ScrollProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const sectionRefs = {
        careerHighlights: useRef<HTMLDivElement>(null),
        aboutMe: useRef<HTMLDivElement>(null),
        swipingZone: useRef<HTMLDivElement>(null),
    };

    const scrollToSection = (section: string) => {
        // @ts-ignore
        const ref = sectionRefs[section];
        if (ref?.current) {
            ref.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    return (
        <ScrollContext.Provider value={{ sectionRefs, scrollToSection, scrollToTop }}>
            {children}
        </ScrollContext.Provider>
    );
};

export const useScroll = (): ScrollContextProps => {
    const context = useContext(ScrollContext);
    if (!context) {
        throw new Error('useScroll must be used within a ScrollProvider');
    }
    return context;
};
