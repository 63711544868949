import React from 'react';

const Portfolio: React.FC = () => {
    return (
        <div className="flex flex-col items-center justify-center h-[400px]">
            <h1 className="text-4xl text-nisipeanu-white">Oops</h1>
            <p className="text-lg text-nisipeanu-white">I didnt finish coding this part yet.</p>
        </div>
    );
}

export default Portfolio;