import React, { useState, useEffect } from "react";
import MonitorDisplay from "../form/monitor/computerMonitor";
import MatrixRainingLetters from "../effects/FlowingMatrix";
import { ReactComponent as Branding } from '../../assets/Branding/correctBranding.svg';
import { motion } from 'framer-motion';
import ScrollDown from "../effects/ScrollDown/scrollDown";
import { getDeviceType, applyResponsiveClasses } from "../../utils/DeviceDetector";
import { useTranslation } from 'react-i18next';

function MonitorPresentation() {
    const [deviceType, setDeviceType] = useState<'phone' | 'tablet' | 'laptop' | 'pc'>('phone');
    const { t } = useTranslation(); 

    useEffect(() => {
        const updateDeviceType = () => setDeviceType(getDeviceType());
        updateDeviceType();
        window.addEventListener('resize', updateDeviceType);
        return () => window.removeEventListener('resize', updateDeviceType);
    }, []);

    const randomDelay = () => Math.random() * 0.5;

    const floatAnimation = {
        initial: { y: -60 },
        animate: { y: 20 },
        transition: { duration: 2, yoyo: Infinity, ease: "easeInOut", delay: randomDelay() }
    };

    const paddingClass = applyResponsiveClasses(deviceType, {
        phone: 'pt-16',  
        tablet: 'lg:pt-20', 
        laptop: '',  
        pc: 'lg:pt-20'  
    });

    const leftRightMonitorScale = deviceType === 'laptop' ? 0.5 : deviceType === 'pc' ? 0.9 : 0.9;
    
    // Increased the scale for mobile (phone) to make the monitor larger
    const centerMonitorScale = deviceType === 'phone' ? 1.2 : deviceType === 'laptop' ? 0.8 : deviceType === 'pc' ? 1.2 : 1.2;

    return (
        <div className={`relative w-full h-screen bg-nisipeanu-black-rau flex items-start justify-center overflow-hidden ${paddingClass}`}>
            <motion.div className="hidden md:block mx-[-256px] z-10 pt-20" {...floatAnimation}>
                <MonitorDisplay scale={leftRightMonitorScale}>
                    <MatrixRainingLetters key="matrix0" color="#068cc2" custom_class="matrix-style" />
                </MonitorDisplay>
            </motion.div>

            <div className="mx-[-24px] z-20 lg:pt-20 pt-32">
                <MonitorDisplay scale={centerMonitorScale} scrollable={false}>
                    <div className="relative flex flex-col items-center justify-center h-full w-full">
                        <div className="absolute top-0 left-0 w-full h-full">
                            <MatrixRainingLetters key="matrix1" color="#068cc2" custom_class="matrix-style" opacity={0.5} />
                        </div>
                        <div className="z-10 flex flex-col items-center justify-center w-full">
                            <Branding className="h-12 w-auto sm:h-14 md:h-16 lg:h-20" />
                            <p className="text-white text-center mt-4 text-xs sm:text-sm md:text-base lg:text-md">
                                {t('home.monitorPresentation.slogan')}
                            </p>
                        </div>
                    </div>
                </MonitorDisplay>
            </div>

            <motion.div className="hidden md:block mx-[-256px] z-10 pt-20" {...floatAnimation}>
                <MonitorDisplay scale={leftRightMonitorScale}>
                    <MatrixRainingLetters key="matrix2" color="#068cc2" custom_class="matrix-style" />
                </MonitorDisplay>
            </motion.div>

            <ScrollDown />
        </div>
    );
}

export default MonitorPresentation;
