import React, { useRef, useEffect } from "react";

interface MatrixProps {
    key: string;
    color?: string;
    custom_class?: string;
    opacity?: number; // Add an opacity prop
}

const renderMatrix = (ref: React.RefObject<HTMLCanvasElement>, color: string = "#0F0", opacity: number = 1) => {
    const canvas = ref.current;
    if (!canvas) return;

    const context = canvas.getContext("2d");
    if (!context) return;

    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight;

    const alphabet = "01";  // Simplified alphabet for demonstration

    const fontSize = 16;
    const columns = canvas.width / fontSize;

    const rainDrops: number[] = new Array(Math.floor(columns)).fill(1);

    const render = () => {
        context.fillStyle = "rgba(0, 0, 0, 0.07)";
        context.fillRect(0, 0, canvas.width, canvas.height);

        // Apply the provided color and opacity to the text
        context.fillStyle = `rgba(${hexToRGB(color)}, ${opacity})`;
        context.font = `${fontSize}px monospace`;

        for (let i = 0; i < rainDrops.length; i++) {
            const text = alphabet.charAt(Math.floor(Math.random() * alphabet.length));
            context.fillText(text, i * fontSize, rainDrops[i] * fontSize);

            if (rainDrops[i] * fontSize > canvas.height && Math.random() > 0.975) {
                rainDrops[i] = 0;
            }
            rainDrops[i]++;
        }
    };
    return render;
};

// Helper function to convert hex color to RGB
function hexToRGB(hex: string) {
    let r = parseInt(hex.slice(1, 3), 16),
        g = parseInt(hex.slice(3, 5), 16),
        b = parseInt(hex.slice(5, 7), 16);
    return `${r},${g},${b}`;
}

const MatrixRainingLetters: React.FC<MatrixProps> = ({ key, color = "#0F0", custom_class = "", opacity = 1 }) => {
    const ref = useRef<HTMLCanvasElement>(null);
    const keyName = `mrl-${key}`;
    const thisClassName = `mrl-container ${custom_class}`;

    useEffect(() => {
        const render = renderMatrix(ref, color, opacity);
        // @ts-ignore
        const intervalId = setInterval(render, 30);
        return () => clearInterval(intervalId);
    }, [color, opacity]);

    return (
        <React.Fragment>
            <canvas key={keyName} className={thisClassName} ref={ref} />
        </React.Fragment>
    );
};

export default MatrixRainingLetters;
