import { toast, ToastOptions, ToastPosition } from 'react-toastify';

const NotificationManager = {
    showNotification: (type: number, message: string, options: ToastOptions = {}) => {
        const commonOptions: ToastOptions = {
            position: "top-right" as ToastPosition,
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'colored',
            ...options
        };

        switch (type) {
            case 1:
                toast.success(message, commonOptions);
                break;
            case 2:
                toast.error(message, commonOptions);
                break;
            case 3:
                toast.info(message, commonOptions);
                break;
            default:
                console.error('Invalid notification type');
        }
    }
};

export default NotificationManager;
