import React from 'react';
import NisipeanuButton from '../../../components/ui/nisipeanuButton';
import { useTranslation } from 'react-i18next';

const BaaaharapBaaalbComponent = () => {
    const { t } = useTranslation();

    return (
        <div className="p-4 max-w-7xl mx-auto">
            <h1 className="text-4xl md:text-5xl font-bold mb-4 text-center text-nisipeanu-blue">
                {t('projects.baaaharap.title')}
            </h1>
            <p className="mb-4 text-center text-nisipeanu-white text-lg md:text-xl">
                {t('projects.baaaharap.prizes')}
                <br /> {t('projects.baaaharap.team')}
            </p>

            <div className="mb-8 text-nisipeanu-white">
                <h2 className="text-3xl md:text-4xl font-bold mb-2">
                    {t('projects.baaaharap.storyTitle')}
                </h2>
                <p className="mb-4 text-base md:text-lg">
                    {t('projects.baaaharap.story1')}
                </p>
                <p className="mb-4 text-base md:text-lg">
                    {t('projects.baaaharap.story2')}
                </p>
            </div>

            <div className="mb-8 text-nisipeanu-white">
                <h2 className="text-3xl md:text-4xl font-bold mb-2">
                    {t('projects.baaaharap.awardsTitle')}
                </h2>
                <p className="mb-4 text-base md:text-lg">
                    {t('projects.baaaharap.awards')}
                </p>
            </div>

            <div className="mb-8 text-nisipeanu-white">
                <h2 className="text-3xl md:text-4xl font-bold mb-2">
                    {t('projects.baaaharap.teamTitle')}
                </h2>
                <ul className="list-disc list-inside space-y-2 text-base md:text-lg">
                    <li>
                        <strong className="text-nisipeanu-blue">Ionuț Nisipeanu</strong>
                        <ul className="list-disc list-inside ml-4">
                            <li>{t('projects.baaaharap.ionut1')}</li>
                            <li>{t('projects.baaaharap.ionut2')}</li>
                            <li>{t('projects.baaaharap.ionut3')}</li>
                            <li>{t('projects.baaaharap.ionut4')}</li>
                        </ul>
                    </li>
                    <li>
                        <strong>Andrei Burlacu</strong>
                        <ul className="list-disc list-inside ml-4">
                            <li>{t('projects.baaaharap.andrei1')}</li>
                            <li>{t('projects.baaaharap.andrei2')}</li>
                            <li>{t('projects.baaaharap.andrei3')}</li>
                            <li>{t('projects.baaaharap.andrei4')}</li>
                        </ul>
                    </li>
                    <li>
                        <strong>Robert Cuc</strong>
                        <ul className="list-disc list-inside ml-4">
                            <li>{t('projects.baaaharap.robert1')}</li>
                            <li>{t('projects.baaaharap.robert2')}</li>
                            <li>{t('projects.baaaharap.robert3')}</li>
                        </ul>
                    </li>
                    <li>
                        <strong>David Vata</strong>
                        <ul className="list-disc list-inside ml-4">
                            <li>{t('projects.baaaharap.david1')}</li>
                            <li>{t('projects.baaaharap.david2')}</li>
                            <li>{t('projects.baaaharap.david3')}</li>
                        </ul>
                    </li>
                    <li>
                        <strong>Florin Viorică</strong>
                        <ul className="list-disc list-inside ml-4">
                            <li>{t('projects.baaaharap.florin1')}</li>
                            <li>{t('projects.baaaharap.florin2')}</li>
                            <li>{t('projects.baaaharap.florin3')}</li>
                        </ul>
                    </li>
                </ul>
            </div>

            <div className="mb-8 text-nisipeanu-white">
                <h2 className="text-3xl md:text-4xl font-bold mb-2">
                    {t('projects.baaaharap.toolsTitle')}
                </h2>
                <p className="mb-4 text-base md:text-lg">
                    {t('projects.baaaharap.toolsIntro')}
                </p>
                <ul className="list-disc list-inside space-y-2 text-base md:text-lg">
                    <li>{t('projects.baaaharap.tool1')}</li>
                    <li>{t('projects.baaaharap.tool2')}</li>
                    <li>{t('projects.baaaharap.tool3')}</li>
                    <li>{t('projects.baaaharap.tool4')}</li>
                    <li>{t('projects.baaaharap.tool5')}</li>
                </ul>
            </div>

            <div className="mb-8">
                <img
                    src="/Images/Projects/Baharaap-baalb/baharaap-baalb.png"
                    alt="Project Background"
                    className="w-full h-auto rounded-lg"
                />
            </div>

            <div className="mb-8 text-nisipeanu-white">
                <h2 className="text-3xl md:text-4xl font-bold mb-2">
                    {t('projects.baaaharap.conclusionTitle')}
                </h2>
                <p className="mb-4 text-base md:text-lg">
                    {t('projects.baaaharap.conclusion')}
                </p>
            </div>

            <div className="flex justify-center">
                <NisipeanuButton
                    text={t('projects.baaaharap.githubButton')}
                    onClick={() => window.open('https://github.com/Limp-Rabbit-Studio/baaaharap-baaalb', '_blank')}
                />
            </div>
            <div className="flex justify-center mt-2">
                <NisipeanuButton
                    text={t('projects.baaaharap.downloadButton')}
                    onClick={() => window.open('https://flo28rin.itch.io/baaaharap-baaalb', '_blank')}
                />
            </div>
        </div>
    );
};

export default BaaaharapBaaalbComponent;
