import React, { useRef, useEffect } from 'react';
import * as THREE from 'three';

const AboutMeBackground: React.FC = () => {
    const mountRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        if (!mountRef.current) return;

        const width = mountRef.current.clientWidth;
        const height = mountRef.current.clientHeight;

        const scene = new THREE.Scene();
        const camera = new THREE.PerspectiveCamera(75, width / height, 0.05, 200);
        const renderer = new THREE.WebGLRenderer({ antialias: true });
        renderer.setClearColor(new THREE.Color('#2b2c2b'));
        renderer.setSize(width, height);
        mountRef.current.appendChild(renderer.domElement);
        camera.position.z = 50;

        const zeroTexture = createTextTexture('0', '#068cc2');
        const oneTexture = createTextTexture('1', '#068cc2');
        const { zeroParticles, oneParticles } = createParticles(scene, zeroTexture, oneTexture, 5000);

        const onResize = () => {
            if (!mountRef.current) return;
            const width = mountRef.current.clientWidth;
            const height = mountRef.current.clientHeight;
            camera.aspect = width / height;
            camera.updateProjectionMatrix();
            renderer.setSize(width, height);
        };

        window.addEventListener('resize', onResize);

        const animate = () => {
            requestAnimationFrame(animate);
            zeroParticles.rotation.y += 0.005;
            oneParticles.rotation.y += 0.005;
            renderer.render(scene, camera);
        };
        animate();

        return () => {
            window.removeEventListener('resize', onResize);
            if (mountRef.current) {
                mountRef.current.removeChild(renderer.domElement);
            }
            scene.clear();
            renderer.dispose();
        };
    }, []);

    function createTextTexture(text: string, color: string): THREE.Texture {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        if (!ctx) throw new Error("Cannot create canvas context");
        canvas.width = 256;
        canvas.height = 256;
        ctx.fillStyle = color;
        ctx.font = '30px Arial';
        ctx.textAlign = 'center';
        ctx.textBaseline = 'middle';
        ctx.fillText(text, 128, 128);

        const texture = new THREE.Texture(canvas);
        texture.needsUpdate = true;
        return texture;
    }

    function createParticles(scene: THREE.Scene, zeroTexture: THREE.Texture, oneTexture: THREE.Texture, count: number): { zeroParticles: THREE.Points; oneParticles: THREE.Points } {
        const zeroGeometry = new THREE.BufferGeometry();
        const oneGeometry = new THREE.BufferGeometry();
        const positionsZero = new Float32Array(count * 3);
        const positionsOne = new Float32Array(count * 3);

        for (let i = 0; i < count; i++) {
            positionsZero.set([Math.random() * 100 - 50, Math.random() * 100 - 50, Math.random() * 100 - 50], i * 3);
            positionsOne.set([Math.random() * 100 - 50, Math.random() * 100 - 50, Math.random() * 100 - 50], i * 3);
        }

        zeroGeometry.setAttribute('position', new THREE.BufferAttribute(positionsZero, 3));
        oneGeometry.setAttribute('position', new THREE.BufferAttribute(positionsOne, 3));

        const zeroMaterial = new THREE.PointsMaterial({ map: zeroTexture, size: 2, transparent: true });
        const oneMaterial = new THREE.PointsMaterial({ map: oneTexture, size: 2, transparent: true });

        const zeroParticles = new THREE.Points(zeroGeometry, zeroMaterial);
        const oneParticles = new THREE.Points(oneGeometry, oneMaterial);

        scene.add(zeroParticles, oneParticles);

        return { zeroParticles, oneParticles };
    }

    return <div ref={mountRef} style={{ width: '100%', height: '100%' }} />;
};

export default AboutMeBackground;
