import React from 'react';
import SwipingCard from '../../components/ui/swipingCard';
import Contact from "./Contact";
import Portofolio from "./Portofolio";

const SwipingZone: React.FC = () => {
    const pages = [
        <Contact />,
        <Portofolio />
    ];

    return <SwipingCard data={pages} />;
};

export default SwipingZone;
