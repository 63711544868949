import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import HomePage from "./pages/Home";
import AboutPage from "./pages/AboutMe";
import PortfolioPage from "./pages/Portfolio";
import Header from "./components/header";
import Footer from "./components/footer";
import { ScrollProvider } from "./utils/ScrollContext";
import {ToastContainer} from "react-toastify";
import LanguageProvider from './utils/LanguageContext';
import { Helmet, HelmetProvider } from 'react-helmet-async';

const App: React.FC = () => {
    return (
    <HelmetProvider>
        <Router>
            <ScrollProvider>
                <LanguageProvider>
                <div className="flex flex-col min-h-screen">
                    <Header />
                    <main className="flex-grow">
                        <ToastContainer />
                        <Routes>
                            <Route path="/" element={<HomePage />} />
                            <Route path="/portfolio" element={<PortfolioPage />} />
                        </Routes>
                    </main>
                    <Footer />
                </div>
                </LanguageProvider>
            </ScrollProvider>
        </Router>
    </HelmetProvider>
    );
}

export default App;
