import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import enTranslations from './locales/en.json';
import roTranslations from './locales/ro.json';

// Configure i18next
i18n.use(initReactI18next).init({
  resources: {
    en: { translation: enTranslations },
    ro: { translation: roTranslations },
  },
  lng: 'ro', // Default language
  fallbackLng: 'ro', // Fallback language
  interpolation: {
    escapeValue: false, // React already escapes
  },
});

export default i18n;

// Add this empty export to resolve the TS1208 error
export {};
