import React, { useState, useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { getDeviceType, applyResponsiveClasses } from "../../utils/DeviceDetector";

interface CardProps {
    data: React.ReactNode[];
}

const SwipingCard: React.FC<CardProps> = ({ data }) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const controls = useAnimation();
    const [direction, setDirection] = useState<'left' | 'right'>('left');
    const [deviceType, setDeviceType] = useState<'phone' | 'tablet' | 'laptop' | 'pc'>('phone');

    useEffect(() => {
        // Update the device type on component mount and window resize
        const updateDeviceType = () => setDeviceType(getDeviceType());
        
        updateDeviceType();
        window.addEventListener('resize', updateDeviceType);

        return () => window.removeEventListener('resize', updateDeviceType);
    }, []);

    const handleDragEnd = (event: MouseEvent | TouchEvent | PointerEvent, info: { offset: { x: number } }) => {
        if (info.offset.x < -100) {
            handleSwipe('left');
        } else if (info.offset.x > 100) {
            handleSwipe('right');
        } else {
            controls.start({ x: 0 });
        }
    };

    const handleSwipe = (swipeDirection: 'left' | 'right') => {
        setDirection(swipeDirection);
        const newIndex = swipeDirection === 'left' ? currentIndex + 1 : currentIndex - 1;

        controls.start({
            x: swipeDirection === 'left' ? '-100%' : '100%',
            transition: { duration: 0.8 },
        }).then(() => {
            setCurrentIndex((prevIndex) => (newIndex < 0 ? data.length - 1 : newIndex % data.length));
            controls.start({ x: 0 });
        });
    };

    // Tailwind height classes based on the device type
    const heightClass = applyResponsiveClasses(deviceType, {
        phone: 'h-auto',  // Default height for phones
        tablet: 'md:h-4/5',  // 80% of the height for tablets
        laptop: 'lg:h-full',  // Larger height for laptops
        pc: 'md:h-4/5',  // Default height for desktops
    });

    return (
        <div className={`flex items-center justify-center h-screen bg-gray-100 overflow-hidden relative`}>
            {/* Background color taking 20% of the screen */}
            <div className="absolute inset-0 bg-nisipeanu-grey"></div>
            <div className={`relative w-full md:w-4/5 ${heightClass} max-w-6xl flex items-center justify-center z-10 p-4`}>
                <motion.div
                    className="relative w-full h-full p-4 bg-nisipeanu-black rounded-lg shadow-lg"
                    key={currentIndex}
                    initial={{ x: direction === 'left' ? '100%' : '-100%' }}
                    animate={{ x: 0 }}
                    exit={{ x: direction === 'left' ? '-100%' : '100%' }}
                    drag="x"
                    dragConstraints={{ left: 0, right: 0 }}
                    onDragEnd={handleDragEnd}
                >
                    <button
                        onClick={() => handleSwipe('right')}
                        className="absolute left-0 top-1/2 transform -translate-y-1/2 px-2 md:px-4 py-1 md:py-2 text-xl md:text-2xl font-bold text-nisipeanu-white rounded-full"
                    >
                        <FaChevronLeft />
                    </button>
                    <div className="flex items-center justify-center h-full text-lg md:text-4xl font-bold text-gray-800">
                        <div className="w-full h-full flex items-center justify-center overflow-hidden">
                            <div className="w-full h-full flex items-center justify-center">
                                {data[currentIndex % data.length]}
                            </div>
                        </div>
                    </div>
                    <button
                        onClick={() => handleSwipe('left')}
                        className="absolute right-0 top-1/2 transform -translate-y-1/2 px-2 md:px-4 py-1 md:py-2 text-xl md:text-2xl font-bold rounded-full"
                    >
                        <FaChevronRight />
                    </button>
                </motion.div>
            </div>
        </div>
    );
};

export default SwipingCard;
