import React from 'react';
import NisipeanuButton from '../../../components/ui/nisipeanuButton';
import { useTranslation } from 'react-i18next';

const CudragoEducatoareComponent = () => {
    const { t } = useTranslation();

    return (
        <div className="p-4 max-w-7xl mx-auto">
            <img src="https://www.cudragoeducatoare.ro/wp-content/uploads/2024/01/Website-logo-test-01-2.png" alt="Cu drag, o Educatoare! Logo" className="w-64 h-auto mx-auto mb-4" />

            <div className="mb-8 text-nisipeanu-white">
                <h2 className="text-3xl md:text-4xl font-bold mb-2">{t('projects.cudragoeducatoare.overviewTitle')}</h2>
                <p className="mb-4 text-base md:text-lg">{t('projects.cudragoeducatoare.overview1')}</p>
                <p className="mb-4 text-base md:text-lg">{t('projects.cudragoeducatoare.overview2')}</p>
                <p className="mb-4 text-base md:text-lg">{t('projects.cudragoeducatoare.overview3')}</p>
            </div>

            <div className="mb-8 text-nisipeanu-white">
                <h2 className="text-3xl md:text-4xl font-bold mb-2">{t('projects.cudragoeducatoare.developmentTitle')}</h2>
                <p className="mb-4 text-base md:text-lg">{t('projects.cudragoeducatoare.development1')}</p>
                <ul className="list-disc list-inside space-y-2 text-base md:text-lg">
                    <li>{t('projects.cudragoeducatoare.tool1')}</li>
                    <li>{t('projects.cudragoeducatoare.tool2')}</li>
                    <li>{t('projects.cudragoeducatoare.tool3')}</li>
                    <li>{t('projects.cudragoeducatoare.tool4')}</li>
                    <li>{t('projects.cudragoeducatoare.tool5')}</li>
                </ul>
            </div>

            <div className="mb-8 text-nisipeanu-white">
                <h2 className="text-3xl md:text-4xl font-bold mb-2">{t('projects.cudragoeducatoare.challengesTitle')}</h2>
                <p className="mb-4 text-base md:text-lg">{t('projects.cudragoeducatoare.challenges1')}</p>
                <p className="mb-4 text-base md:text-lg">{t('projects.cudragoeducatoare.challenges2')}</p>
            </div>

            <div className="mb-8 text-nisipeanu-white">
                <h2 className="text-3xl md:text-4xl font-bold mb-2">{t('projects.cudragoeducatoare.conclusionTitle')}</h2>
                <p className="mb-4 text-base md:text-lg">{t('projects.cudragoeducatoare.conclusion1')}</p>
                <p className="mb-4 text-base md:text-lg">{t('projects.cudragoeducatoare.conclusion2')}</p>
            </div>

            <div className="flex justify-center">
                <NisipeanuButton
                    text="Visit Website"
                    onClick={() => window.open('https://cudragoeducatoare.ro', '_blank')}
                />
            </div>
        </div>
    );
};

export default CudragoEducatoareComponent;
