import React, { useState, useEffect } from 'react';
import { motion, useAnimation, useInView } from 'framer-motion';
import NisipeanuButton from "../../components/ui/nisipeanuButton";
import useCareerData from './careerData'; // Import the custom hook for career data
import { useTranslation } from 'react-i18next'; // Import the translation hook
const CareerHighlights: React.FC = () => {
    const [filter, setFilter] = useState<'all' | 'personal' | 'professional'>('all');
    const controls = useAnimation();
    const ref = React.useRef<HTMLDivElement>(null);
    const isInView = useInView(ref, { once: true });
    const { t } = useTranslation(); // Initialize the translation hook

    const careerData = useCareerData(); // Get the translated career data from the hook

    useEffect(() => {
        if (isInView) {
            controls.start(i => ({
            opacity: 1,
                transition: { delay: i * 0.6 }
            }));
        }
    }, [controls, isInView]);

    useEffect(() => {
        controls.start('hidden').then(() => controls.start('visible'));
    }, [filter]);

    const containerVariants = {
        hidden: { opacity: 1 },
        visible: {
            opacity: 1,
            transition: {
                staggerChildren: 0.6,
            }
        }
    };

    const lineVariants = {
        hidden: { scaleX: 0 },
        visible: { scaleX: 1, transition: { duration: 0.4 } }
    };

    const circleVariants = {
        hidden: { scale: 0 },
        visible: { scale: 1, transition: { duration: 0.2 } }
    };

    const verticalLineVariants = {
        hidden: { scaleY: 0 },
        visible: { scaleY: 1, transition: { duration: 0.4 } }
    };

    const filteredData = careerData.filter(item =>
        filter === 'all' || item.category === filter
    ).sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());

    return (
        <div className='bg-nisipeanu-black min-h-screen flex flex-col items-center justify-center text-white p-8 md:p-8'>
            <div className='relative w-full max-w-5xl p-4 md:p-8 bg-opacity-50 bg-nisipeanu-black-rau rounded-lg shadow-lg'>
                <div className='text-left mb-4 md:mb-8 w-full'>
                    <h1 className='text-3xl md:text-5xl font-bold text-nisipeanu-white mb-2 md:mb-4'>
                        {/* Translated title */}
                        {t('career.title')}
                    </h1>
                    <div ref={ref} className="mb-4 flex flex-col items-start">
                        <div className="relative w-full flex">
                            <motion.div
                                className="h-2 w-64 md:w-96 bg-nisipeanu-blue shadow-glow"
                                initial="hidden"
                                animate={controls}
                                variants={lineVariants}
                                style={{transformOrigin: 'left'}}
                            />
                            <motion.div
                                className="w-6 h-6 md:w-8 md:h-8 rounded-full border-8 border-nisipeanu-blue shadow-glow left-64 md:left-96 bottom-[-10px]"
                                initial="hidden"
                                animate={controls}
                                variants={circleVariants}
                                style={{position: 'absolute'}}
                            />
                        </div>
                    </div>
                    <p className='text-sm md:text-base text-gray-300 mb-4'>
                        {/* Translated description */}
                        {t('career.description')}
                    </p>
                    <div className='flex space-x-2 md:space-x-4 mb-4 md:mb-8'>
                        {/* Filter buttons with translations */}
                        <NisipeanuButton text={t('career.filters.all')} onClick={() => setFilter('all')}/>
                        <NisipeanuButton text={t('career.filters.personal')} onClick={() => setFilter('personal')}/>
                        <NisipeanuButton text={t('career.filters.professional')} onClick={() => setFilter('professional')}/>
                    </div>
                </div>
                <motion.div className='timeline w-full' variants={containerVariants} initial="hidden"
                            animate={controls}>
                    {filteredData.map((item, index) => (
                        <motion.div key={index}
                                    className='timeline-item flex flex-col items-start mb-4 md:mb-8 p-4 relative w-full'
                                    custom={index} variants={containerVariants}>
                            <div className='absolute -left-6 md:-left-8 top-0 flex flex-col items-center h-full'>
                                <motion.div
                                    className={`w-1.5 md:w-2 flex-grow ${item.category === 'professional' ? 'bg-nisipeanu-blue shadow-glow' : 'bg-nisipeanu-orange shadow-glow-orange'}`}
                                    variants={verticalLineVariants}
                                    custom={index * 2}
                                />
                                <motion.div
                                    className={`w-5 md:w-7 h-5 md:h-7 rounded-full border-4 md:border-8 ${item.category === 'professional' ? 'border-nisipeanu-blue shadow-glow' : 'border-nisipeanu-orange shadow-glow-orange'}`}
                                    variants={circleVariants}
                                    custom={(index * 2) + 1}
                                />
                            </div>
                            <div className='flex items-center mb-2 md:mb-4 ml-6 md:ml-8'>
                                <div className='timeline-date text-white font-bold text-sm md:text-base'>{item.date}</div>
                            </div>
                            <div className='timeline-content bg-glow-gradient p-2 md:p-4 rounded-lg w-full'>
                                <h2 className='text-lg md:text-2xl font-semibold mb-1 md:mb-2'>{item.title}</h2>
                                <p className='text-gray-300 text-sm md:text-base'>{item.description}</p>
                            </div>
                        </motion.div>
                    ))}
                </motion.div>
                <div className='flex justify-center mt-4 md:mt-8'>
                    <NisipeanuButton text={t('career.portfolioButton')} onClick={() => window.open('/portfolio', '_self')}/>
                </div>
                <div className="absolute top-2 md:top-4 right-2 md:right-4 shadow-nisipeanu-blue">
                    <motion.div
                        className="h-6 md:h-8 w-6 md:w-8 border-4 md:border-8 border-nisipeanu-blue rounded-full"
                        initial={{ boxShadow: '0 0 8px rgba(0, 122, 206, 0.6)' }}
                        animate={{ boxShadow: ['0 0 8px rgba(0, 122, 206, 0.6)', '0 0 16px rgba(0, 122, 206, 0.9)'] }}
                        transition={{ duration: 1.5, repeat: Infinity, repeatType: 'reverse' }}
                    />
                </div>
            </div>
        </div>
    );
}

export default CareerHighlights;
