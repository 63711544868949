import React from 'react';
import NisipeanuButton from '../../../components/ui/nisipeanuButton';
import { useTranslation } from 'react-i18next';

const HarmonicSplitComponent = () => {
    const { t } = useTranslation();

    return (
        <div className="p-6 max-w-7xl mx-auto">
            <h1 className="text-5xl font-bold mb-8 text-center text-blue-500">{t('projects.harmonicSplit.title')}</h1>
            <div className="text-center mb-12">
                <p className="text-xl text-gray-300">{t('projects.harmonicSplit.subtitle')}</p>
                <p className="text-xl text-gray-300 mb-4">{t('projects.harmonicSplit.createdBy')}</p>
                <p className="text-lg text-gray-400">{t('projects.harmonicSplit.madeFor')}<br /> {t('projects.harmonicSplit.organization')}</p>
                <p className="text-lg text-gray-400">{t('projects.harmonicSplit.award')}</p>
            </div>

            <div className="mb-12 text-gray-200">
                <h2 className="text-3xl font-bold mb-6">{t('projects.harmonicSplit.aboutTitle')}</h2>
                <p className="text-lg mb-4">{t('projects.harmonicSplit.about1')}</p>
                <p className="text-lg mb-4">{t('projects.harmonicSplit.about2')}</p>
                <ul className="list-disc list-inside ml-6 mb-4 text-lg">
                    <li><strong>{t('projects.harmonicSplit.audioSplitter')}</strong></li>
                    <li><strong>{t('projects.harmonicSplit.guitarTuner')}</strong></li>
                </ul>
            </div>

            <div className="mb-12 text-gray-200">
                <h2 className="text-3xl font-bold mb-6">{t('projects.harmonicSplit.technicalTitle')}</h2>
                <p className="text-lg mb-4">{t('projects.harmonicSplit.technical1')}</p>
                <p className="text-lg mb-4">{t('projects.harmonicSplit.technical2')}</p>
                <p className="text-lg mb-4">{t('projects.harmonicSplit.technical3')}</p>
            </div>

            <div className="mb-12">
                <h2 className="text-3xl font-bold mb-6 text-gray-200">{t('projects.harmonicSplit.mainPageTitle')}</h2>
                <p className="text-lg mb-4 text-gray-200">{t('projects.harmonicSplit.mainPageDescription')}</p>
                <img src="Images/Projects/HarmonicSplit/home.png" alt="Main Page" className="w-full h-auto rounded-lg" />
            </div>

            <div className="mb-12">
                <h2 className="text-3xl font-bold mb-6 text-gray-200">{t('projects.harmonicSplit.guitarTunerTitle')}</h2>
                <p className="text-lg mb-4 text-gray-200">{t('projects.harmonicSplit.guitarTunerDescription')}</p>
                <img src="Images/Projects/HarmonicSplit/tuner.png" alt="Guitar Tuner Page" className="w-full h-auto rounded-lg" />
            </div>

            <div className="mb-12">
                <h2 className="text-3xl font-bold mb-6 text-gray-200">{t('projects.harmonicSplit.audioSplitterTitle')}</h2>
                <p className="text-lg mb-4 text-gray-200">{t('projects.harmonicSplit.audioSplitterDescription')}</p>
                <img src="Images/Projects/HarmonicSplit/split.png" alt="Audio Splitter Page" className="w-full h-auto rounded-lg" />
            </div>

            <div className="mb-12">
                <h2 className="text-3xl font-bold mb-6 text-gray-200">{t('projects.harmonicSplit.audioDemosTitle')}</h2>
                <div className="bg-nisipeanu-black p-6 rounded-lg text-center">
                    <p className="text-2xl text-gray-100 font-bold">{t('projects.harmonicSplit.originalSong')}</p>
                    <p className="text-lg text-gray-300 mb-4">Chosen for its popularity and complex mix of vocals and instruments.</p>
                    <div className="mb-8">
                        <audio controls className="w-full">
                            <source src="/Audio/Projects/HarmonicSplit/original.mp3" type="audio/mpeg" />
                            Your browser does not support the audio element.
                        </audio>
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                        <div className="mb-4">
                            <p className="text-xl text-gray-100 font-bold">{t('projects.harmonicSplit.vocals')}</p>
                            <audio controls className="w-full">
                                <source src="/Audio/Projects/HarmonicSplit/vocals.mp3" type="audio/mpeg" />
                                Your browser does not support the audio element.
                            </audio>
                        </div>
                        <div className="mb-4">
                            <p className="text-xl text-gray-100 font-bold">{t('projects.harmonicSplit.drums')}</p>
                            <audio controls className="w-full">
                                <source src="/Audio/Projects/HarmonicSplit/drums.mp3" type="audio/mpeg" />
                                Your browser does not support the audio element.
                            </audio>
                        </div>
                        <div className="mb-4">
                            <p className="text-xl text-gray-100 font-bold">{t('projects.harmonicSplit.bass')}</p>
                            <audio controls className="w-full">
                                <source src="/Audio/Projects/HarmonicSplit/bass.mp3" type="audio/mpeg" />
                                Your browser does not support the audio element.
                            </audio>
                        </div>
                        <div className="mb-4">
                            <p className="text-xl text-gray-100 font-bold">{t('projects.harmonicSplit.otherInstruments')}</p>
                            <audio controls className="w-full">
                                <source src="/Audio/Projects/HarmonicSplit/others.mp3" type="audio/mpeg" />
                                Your browser does not support the audio element.
                            </audio>
                        </div>
                    </div>
                </div>
            </div>

            <div className="flex justify-center">
                <NisipeanuButton
                    text={t('projects.harmonicSplit.githubButton')}
                    onClick={() => window.open('https://github.com/AurasV/Guitar-Tuner-and-AI-Song-Splitter-Web-Application', '_blank')}
                />
            </div>
        </div>
    );
};

export default HarmonicSplitComponent;
