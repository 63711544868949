import React, { ReactNode } from 'react';
import { IoMdClose } from 'react-icons/io';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';  // Import the translation hook
import './styles.css';

interface ProjectCardProps {
    title: string;
    prizes: string[];
    technologies: string[];
    backgroundImage: string;
    projectType: string;
    category: string[];
    children: ReactNode;
    isOpen: boolean;
    onOpen: () => void;
    onClose: () => void;
    isMobile: boolean;
}

const ProjectCard: React.FC<ProjectCardProps> = ({
    title,
    prizes,
    technologies,
    backgroundImage,
    projectType,
    category,
    children,
    isOpen,
    onOpen,
    onClose,
    isMobile,
}) => {
    const { t } = useTranslation(); // Initialize translation hook
    const smallCardStyle =
        'w-full sm:w-80 h-80 bg-nisipeanu-black rounded-lg shadow-lg cursor-pointer overflow-hidden relative';

    return (
        <>
            {!isOpen ? (
                <motion.div
                    className={smallCardStyle}
                    onClick={onOpen}
                    layout
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                >
                    <div
                        className="absolute inset-0"
                        style={{
                            backgroundImage: `url(${backgroundImage})`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            filter: 'blur(8px) opacity(0.6)',
                        }}
                    ></div>
                    <footer className="absolute bottom-0 left-0 right-0 p-4 backdrop-blur-lg bg-white bg-opacity-20 text-white">
                        <div className="relative z-10 text-lg">
                            <div className="flex flex-col space-y-2">
                                <h3 className="font-bold text-nisipeanu-white">{title}</h3>
                                <div className="text-sm text-nisipeanu-white">
                                    <h4 className="font-semibold">
                                        {t('portfolio.projectType')}: {projectType} | {category.join(', ')}
                                    </h4>
                                </div>
                                <div className="text-sm text-nisipeanu-white">
                                    <h4 className="font-semibold">{t('portfolio.prizes')}:</h4>
                                    <p>{prizes.join(', ')}</p>
                                </div>
                                <div className="text-sm text-nisipeanu-white">
                                    <h4 className="font-semibold">{t('portfolio.technology')}:</h4>
                                    <p>{technologies.join(', ')}</p>
                                </div>
                            </div>
                        </div>
                    </footer>
                    <div className="absolute top-2 right-2 shadow-nisipeanu-blue">
                        <motion.div
                            className="h-8 w-8 border-8 border-nisipeanu-blue rounded-full bg-nisipeanu-grey"
                            initial={{ boxShadow: '0 0 8px rgba(0, 122, 206, 0.6)' }}
                            animate={{
                                boxShadow: [
                                    '0 0 8px rgba(0, 122, 206, 0.6)',
                                    '0 0 16px rgba(0, 122, 206, 0.9)',
                                ],
                            }}
                            transition={{ duration: 1.5, repeat: Infinity, repeatType: 'reverse' }}
                        />
                    </div>
                </motion.div>
            ) : (
                <>
                    <motion.div
                        className="fixed inset-0 z-40 backdrop-blur-md bg-black bg-opacity-50"
                        layout
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                    ></motion.div>
                    <motion.div
                        className={`fixed inset-0 z-50 p-4 bg-nisipeanu-grey-light rounded-lg shadow-lg m-auto ${
                            isMobile ? 'w-full h-full' : 'w-[90%] h-[80vh]'
                        } overflow-hidden`}
                        layout
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                    >
                        <motion.div layout className="relative z-10 text-xl p-6 overflow-auto h-full">
                            {children}
                        </motion.div>
                        <motion.button
                            className="absolute top-1 right-6 p-3 z-50 bg-transparent rounded-full flex items-center justify-center"
                            onClick={(e) => {
                                e.stopPropagation();
                                onClose();
                            }}
                            style={{ cursor: 'pointer' }}
                            whileHover={{ scale: 1.1 }}
                            whileTap={{ scale: 0.9 }}
                        >
                            <IoMdClose className="text-3xl text-white glow-icon" />
                        </motion.button>
                    </motion.div>
                </>
            )}
        </>
    );
};

export default ProjectCard;
