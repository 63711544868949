import { useTranslation } from 'react-i18next';

type CareerHighlight = {
    date: string;
    title: string;
    description: string;
    category: 'personal' | 'professional';
};

const useCareerData = (): CareerHighlight[] => {
    const { t } = useTranslation();

    const careerData: CareerHighlight[] = [
        {
            date: '2022-05',
            title: t('career.highlights.infoEducatie.title'),
            description: t('career.highlights.infoEducatie.description'),
            category: 'personal'
        },
        {
            date: '2022-10',
            title: t('career.highlights.politehnica.title'),
            description: t('career.highlights.politehnica.description'),
            category: 'personal'
        },
        {
            date: '2023-02',
            title: t('career.highlights.logiscool.title'),
            description: t('career.highlights.logiscool.description'),
            category: 'professional'
        },
        {
            date: '2023-05',
            title: t('career.highlights.spleeter.title'),
            description: t('career.highlights.spleeter.description'),
            category: 'personal'
        },
        {
            date: '2023-06',
            title: t('career.highlights.mbmSoftware.title'),
            description: t('career.highlights.mbmSoftware.description'),
            category: 'professional'
        },
        {
            date: '2024-03',
            title: t('career.highlights.rgda.title'),
            description: t('career.highlights.rgda.description'),
            category: 'personal'
        },
        {
            date: '2024-06',
            title: t('career.highlights.digitalAssets.title'),
            description: t('career.highlights.digitalAssets.description'),
            category: 'professional'
        }
    ];

    return careerData;
};

export default useCareerData;
