import React from 'react';
import './monitorStylesCRT.css';

interface MonitorDisplayProps {
    children?: React.ReactNode;
    className?: string;
    scale?: number;
    scrollable?: boolean;
    style?: React.CSSProperties;
}

const MonitorDisplay: React.FC<MonitorDisplayProps> = ({
                                                           children,
                                                           className,
                                                           scale = 1,
                                                           scrollable = false,
                                                           style
                                                       }) => {
    // Set overflow properties based on the scrollable prop
    const monitorStyle: React.CSSProperties = {
        overflowY: scrollable ? 'scroll' : 'hidden',
        overflowX: scrollable ? 'scroll' : 'hidden', // Now controls horizontal scrolling too
    };

    // Dynamic style applies primarily to the container
    const dynamicStyle = {
        transform: `scale(${scale})`,
        transformOrigin: 'center center',
        ...style
    };

    return (
        <article className={`monitor-container ${className || ''}`} style={dynamicStyle}>
            <div className="stand">
                <div className="monitor" style={monitorStyle}>
                    {children}
                </div>
            </div>
        </article>
    );
}

export default MonitorDisplay;
