import React from 'react';
import { motion } from 'framer-motion';
import {FaLinkedin, FaInstagram, FaFacebook, FaTwitter, FaGithub} from "react-icons/fa";

type SocialIconProps = {
    Icon: React.ElementType;
    url: string;
};

const hoverVariants = {
    initial: {
        scale: 1,
        filter: "drop-shadow(0 0 0px rgba(0, 0, 0, 0))" // No glow initially
    },
    hover: {
        scale: 1.1,
        filter: "drop-shadow(0 0 6px rgba(25, 118, 210, 0.8))", // Glow effect on hover
        transition: { duration: 0.3, type: "spring", stiffness: 300 }
    }
};

const SocialIcon: React.FC<SocialIconProps> = ({ Icon, url }) => {
    return (
        <motion.a
            href={url}
            target="_blank"
            rel="noopener noreferrer"
            variants={hoverVariants}
            initial="initial"
            whileHover="hover"
            className="text-nisipeanu-white hover:text-nisipeanu-blue transition-colors duration-300"
        >
            <Icon className="text-2xl" />
        </motion.a>
    );
};

const SocialIcons: React.FC = () => {
    return (
        <div className="flex space-x-4 justify-center p-4">
            {/*<SocialIcon Icon={FaInstagram} url="https://www.instagram.com/" />*/}
            <SocialIcon Icon={FaFacebook} url="https://www.facebook.com/people/NISIPEANUtech/61558727866823/" />
            <SocialIcon Icon={FaLinkedin} url="https://www.linkedin.com/in/nisipeanu-ionut-604339246/" />
            <SocialIcon Icon={FaGithub} url="https://github.com/Nasapan23" />
        </div>
    );
};

export default SocialIcons;
