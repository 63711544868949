import React, { createContext, useState, useEffect, ReactNode } from 'react';
import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import Cookies from 'js-cookie';
import enTranslations from '../locales/en.json';
import roTranslations from '../locales/ro.json';

interface LanguageContextProps {
  language: string;
  changeLanguage: (lang: 'en' | 'ro') => void;
}

interface LanguageProviderProps {
  children: ReactNode;
}

// Initialize i18next with the default language as 'ro'
i18next.use(initReactI18next).init({
  resources: {
    en: { translation: enTranslations },
    ro: { translation: roTranslations },
  },
  lng: 'ro', // Default language set to Romanian
  fallbackLng: 'en', // Fallback to English if translation is missing
  interpolation: { escapeValue: false },
});

export const LanguageContext = createContext<LanguageContextProps | undefined>(undefined);

const LanguageProvider: React.FC<LanguageProviderProps> = ({ children }) => {
  const [language, setLanguage] = useState<'en' | 'ro'>('ro'); // Default to Romanian

  useEffect(() => {
    // Check for a saved language in cookies
    const savedLanguage = Cookies.get('lang') as 'en' | 'ro' | undefined;
    if (savedLanguage) {
      setLanguage(savedLanguage);
      i18next.changeLanguage(savedLanguage);
    } else {
      // Set to default language if no cookie is found
      setLanguage('ro');
      i18next.changeLanguage('ro');
      Cookies.set('lang', 'ro'); // Save the default to cookies
    }
  }, []);

  // Function to change language manually
  const changeLanguage = (lang: 'en' | 'ro') => {
    setLanguage(lang);
    i18next.changeLanguage(lang);
    Cookies.set('lang', lang); // Save language preference in cookies
  };

  return (
    <LanguageContext.Provider value={{ language, changeLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
};

export default LanguageProvider;
