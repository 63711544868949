import React from 'react';
import { motion } from 'framer-motion';

type NisipeanuButtonProps = {
    text: string;
    onClick?: () => void;
    scale?: number; // Add scale prop
};

const hoverVariants = {
    initial: (scale: number) => ({
        transform: `scale(${scale})`,
        filter: "drop-shadow(0 0 0px rgba(0, 0, 0, 0))" // No glow initially
    }),
    hover: (scale: number) => ({
        transform: `scale(${scale * 1.1})`, // Keep the hover effect relative to the initial scale
        filter: "drop-shadow(0 0 6px rgba(25, 118, 210, 0.8))", // Glow effect on hover
        transition: { duration: 0.3, type: "spring", stiffness: 300 }
    })
};

const NisipeanuButton: React.FC<NisipeanuButtonProps> = ({ text, onClick, scale = 1 }) => {
    return (
        <motion.button
            onClick={onClick}
            custom={scale} // Pass the scale value as a custom prop to the variants
            variants={hoverVariants}
            initial="initial"
            whileHover="hover"
            className="bg-white text-black font-bold text-sm py-2 px-4 rounded-md shadow-lg hover:bg-nisipeanu-blue transition-colors duration-300 ease-in-out hover:shadow-md"
            style={{ transform: `scale(${scale})` }} // Set initial scale
        >
            {text}
        </motion.button>
    );
};

export default NisipeanuButton;
