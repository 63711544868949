import React, { useEffect } from 'react';
import { motion, useAnimation, useInView } from 'framer-motion';
import AboutMeBackground from "./aboutMeBackground";
import NisipeanuButton from "../../components/ui/nisipeanuButton";
import { useNavigate, useLocation } from 'react-router-dom';
import { useScroll } from '../../utils/ScrollContext';
import { useTranslation } from 'react-i18next'; // Import the translation hook

const AboutMe: React.FC = () => {
    const { t } = useTranslation(); // Initialize the translation hook
    const navigate = useNavigate();
    const location = useLocation();
    const controls = useAnimation();
    const ref = React.useRef<HTMLDivElement>(null);
    const isInView = useInView(ref, { once: true });
    const { scrollToSection, scrollToTop } = useScroll();

    const handleNavClick = (path: string, section?: string) => {
        if (location.pathname === path) {
            if (section) {
                scrollToSection(section);
            } else {
                scrollToTop();
            }
        } else {
            navigate(path, { state: { section } });
        }
    };

    function handleClick() {
        handleNavClick('/', 'swipingZone');
    }

    useEffect(() => {
        if (isInView) {
            controls.start('visible');
        }
    }, [controls, isInView]);

    const lineVariants = {
        hidden: { scaleX: 0 },
        visible: { scaleX: 1, transition: { duration: 0.4 } }
    };

    const circleVariants = {
        hidden: { scale: 0 },
        visible: { scale: 1, transition: { delay: 0.4, duration: 0.2 } }
    };

    return (
        <div className="relative w-full h-[1800px] md:h-[1200px] bg-nisipeanu-grey text-white flex md:py-0 lg:py-8">
            <AboutMeBackground />
            <div className="absolute inset-0 flex items-center justify-center">
                <div className="bg-nisipeanu-black p-4 sm:p-6 md:p-8 lg:p-10 rounded-lg relative w-11/12 md:w-[70%] max-w-6xl">
                    <div className="flex flex-col justify-center">
                        <h1 className="text-3xl md:text-4xl font-bold text-nisipeanu-white mb-4 md:mb-2">
                            {t('home.aboutMe.title')}
                        </h1>
                        <div ref={ref} className="mb-8 md:mb-4 flex flex-col items-center">
                            <div className="relative w-full flex">
                                <motion.div
                                    className="h-2 w-36 md:w-44 bg-nisipeanu-blue shadow-glow"
                                    initial="hidden"
                                    animate={controls}
                                    variants={lineVariants}
                                    style={{ transformOrigin: 'left' }}
                                />
                                <motion.div
                                    className="w-6 h-6 md:w-8 md:h-8 rounded-full border-8 border-nisipeanu-blue shadow-glow left-36 md:left-44 bottom-[-10px]"
                                    initial="hidden"
                                    animate={controls}
                                    variants={circleVariants}
                                    style={{ position: 'absolute' }}
                                />
                            </div>
                        </div>

                        {/* Render HTML inside translation using dangerouslySetInnerHTML */}
                        <div className="text-sm md:text-lg text-gray-300 leading-relaxed">
                            <h2 className="text-xl md:text-2xl font-bold text-white mb-4">
                                {t('home.aboutMe.introduction')}
                            </h2>
                            <p className="mb-4"
                                dangerouslySetInnerHTML={{
                                    __html: t('home.aboutMe.experience', {
                                        years: '4'
                                    })
                                }}
                            />
                            <h2 className="text-xl md:text-2xl font-bold text-white mb-4">
                                {t('home.aboutMe.customSolutionsTitle')}
                            </h2>
                            <p className="mb-4"
                                dangerouslySetInnerHTML={{
                                    __html: t('home.aboutMe.customSolutionsDescription')
                                }}
                            />
                            <h2 className="text-xl md:text-2xl font-bold text-white mb-4">
                                {t('home.aboutMe.innovativeTitle')}
                            </h2>
                            <p className="mb-4"
                                dangerouslySetInnerHTML={{
                                    __html: t('home.aboutMe.innovativeDescription')
                                }}
                            />

                            <div className="mt-6 md:mt-8 lg:mt-10 flex justify-center">
                                <NisipeanuButton onClick={handleClick} text={t('home.aboutMe.cta')} />
                            </div>
                        </div>
                    </div>
                    <div className="absolute top-4 right-4 shadow-nisipeanu-blue">
                        <motion.div
                            className="h-6 w-6 md:h-8 md:w-8 border-8 border-nisipeanu-blue rounded-full"
                            initial={{ boxShadow: '0 0 8px rgba(0, 122, 206, 0.6)' }}
                            animate={{ boxShadow: ['0 0 8px rgba(0, 122, 206, 0.6)', '0 0 16px rgba(0, 122, 206, 0.9)'] }}
                            transition={{ duration: 1.5, repeat: Infinity, repeatType: 'reverse' }}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AboutMe;
