import React from "react";
import SwipingZone from "../SwipingZone";
import BaaaharapBaaalbComponent from "./Projects/BaaaharapBaaalb";
import CudragoEducatoareComponent from "./Projects/cudragoeducatoare.ro";
import HarmonicSplit from "./Projects/HarmonicSplit";

const projects = [
    {
        title: "The Story of 'Baaaharap Baaalb'",
        prizes: ['Best Graphics - RGDA 2024 GAME JAM'],
        technologies: ['Unity', 'C#'],
        category: 'Game Development',
        projectType: 'Personal',
        backgroundImage: '/Images/Projects/Baharaap-baalb/baharaap-baalb.png',
        component: (
             <BaaaharapBaaalbComponent />
        )
    },
    {
        title: 'Cudragoeducatoare.ro',
        prizes: ['Working with @Cudragoeducatoare.ro'],
        technologies: ['Wordpress', 'JavaScript', 'CSS','PHP'],
        category: 'Web Development',
        projectType: 'Professional',
        backgroundImage: 'Images/Projects/cudragoeducatoare/background.png',
        component: (
            <CudragoEducatoareComponent/>
        )
    },
    {
        title: 'Harmonic Split',
        prizes: ['2nd Place - Scientific Communications Session @ F.I.L.S UPB'],
        technologies: ['Angular', 'JavaScript', 'SCSS'],
        category: ['Fullstack'],
        projectType: 'Professional',
        backgroundImage: 'Images/Projects/HarmonicSplit/home.png',
        component: (
            <HarmonicSplit />
        )
    },
    // {
    //     title: 'Nisipeanu.tech',
    //     prizes: ['My Heart <3'],
    //     technologies: ['React.js', 'Framer Motion', 'TailwindCSS','React Three Fiber'],
    //     category: ['Frontend'],
    //     projectType: 'Personal',
    //     backgroundImage: 'Images/Projects/Nisipeanu/logo.jpg',
    //     component: (
    //         <HarmonicSplit />
    //     )
    // },
];
export default projects;
